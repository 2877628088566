import defaultAxios, { AxiosRequestConfig, AxiosError, AxiosInstance } from 'axios'
import moment from 'moment'
import Qs from 'qs'
import Api, { StatusIsValidate, StatusCodeSent, StatusUnlockAccount, IResponseCreateAccount, StatusStatus, IResponseCompletionCertificate, StatusIsUpdate, IRequestGenerateUnlockCode, IRequestValidateUnlockCode, IRequestCreateAccount, IRequestLogRegHit, IRequestUpdateDocuments, IRequestUpdateFinanceData, IRequestStoreIncompleteFinance, IRequestSendDocumentsToCustomer, IRequestSendDocuments, IRequestUpdateAccount, IRequestCompleteFinanceDecline, IRequestUpdateAndResendAccountEmails, IRequestViewUnlockCode, IRequestCancelIncomplete, IRequest, IResultStatus, AuthTokenResponse, IRequestLoadExistingAccount, IResponseExistingAccountInfo, InvalidPhoneNumberError, NextSubidResponse } from './api'

const CACHED_EMAIL_VALIDATIONS: {
  [email: string]: Promise<StatusIsValidate>
} = {}

export type ApiOptions = {
  /** leave this blank to turn off watch dog for cookie based sessions otherwise set to cookie session timeout */
  secondsBeforeExpire?: number;
  logoutUrl?: string;
  axios?: AxiosInstance
  priceCode?: string
}

export default class IrisApi extends Api {
  constructor (basePath: string, reghitId: number, { secondsBeforeExpire, logoutUrl = 'logout', axios, priceCode }: ApiOptions = {}) {
    super(basePath, reghitId)
    this._logoutUrl = logoutUrl
    this.axios = axios || defaultAxios
    this.priceCode = priceCode
    if (secondsBeforeExpire && secondsBeforeExpire > 0) {
      this._sessionTime = (secondsBeforeExpire / 2) * 1000
    }
    this.startWatchDog()
  }

  private _sessionTime?: number
  private _logoutUrl?: string
  private _timeoutId?: number
  private readonly axios: AxiosInstance
  private readonly priceCode?: string

  getEstiaLabsNextSubid(): Promise<NextSubidResponse> {
    // bit of anyscript but this api doesn't conform to the ones in iris controller
    return this.post<any, any>('../api/iris/nextEstialabsSubscriberID')
  }

  validateEmail (email: string, options: { instituteCode?: string } = {}) {
    if (typeof CACHED_EMAIL_VALIDATIONS[email] === 'undefined') {
      CACHED_EMAIL_VALIDATIONS[email] = new Promise((resolve, reject) => {
        this.post<StatusIsValidate, 'isValidate'>('validate-email', Object.assign({ email }, options)).then(resolve).catch(error => {
          delete CACHED_EMAIL_VALIDATIONS[email]
          reject(error)
        })
      })
    }
    return CACHED_EMAIL_VALIDATIONS[email]
  }

  generateUnlockCode (phoneNumberAndLocation: IRequestGenerateUnlockCode) {
    return this.post<StatusCodeSent, 'codeSent'>('generate-unlock-code', phoneNumberAndLocation).catch((error: AxiosError) => {
      // extract the message and just create a plain error
      if (error.response && error.response.status === 400) {
        throw new InvalidPhoneNumberError((error.response.data as StatusCodeSent).message)
      }
      throw error // other error
    })
  }

  validateUnlockCode (unlockCodeAndLocation: IRequestValidateUnlockCode) {
    return this.post<StatusUnlockAccount, 'unlockAccount'>('validate-unlock-code', unlockCodeAndLocation)
  }

  createAccount (accountData: IRequestCreateAccount) {
    return this.post<IResponseCreateAccount, 'isCreate'>('create-account', accountData)
  }

  startWatchDog () {
    this.stopWatchDog()
    if (this._sessionTime) {
      this._timeoutId = window.setTimeout(async () => {
        try {
          await this.post('ping')
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Failed session keep alive ping', e)
        }
      }, this._sessionTime)
    }
  }

  stopWatchDog () {
    if (this._timeoutId) {
      window.clearTimeout(this._timeoutId)
      this._timeoutId = undefined
    }
  }

  logReghit (regHit: IRequestLogRegHit) {
    return this.post<StatusStatus, 'status'>('log-reghit-txn', regHit)
  }

  updateDocuments (documentsData: IRequestUpdateDocuments) {
    return this.post<StatusStatus, 'status'>('update-documents', documentsData)
  }

  updateFinanceData (financeData: IRequestUpdateFinanceData) {
    return this.post<StatusStatus, 'status'>('update-finance', financeData)
  }

  getCompletionCertificateData (subscriberId: number) {
    return this.post<IResponseCompletionCertificate, 'status'>('generate-completion-certificate', { subscriberId })
  }

  storeIncompleteFinance (incompleteData: IRequestStoreIncompleteFinance) {
    return this.post<StatusStatus, 'status'>('store-incomplete-finance', incompleteData)
  }

  updateViewUnlockCodeLocation (phoneNumberPositionAndUnlockCode: IRequestViewUnlockCode) {
    return this.post<StatusStatus, 'status'>('update-view-unlock-code-location', phoneNumberPositionAndUnlockCode)
  }

  sendDocumentsToCustomer (documentsData: IRequestSendDocumentsToCustomer) {
    return this.post<StatusStatus, 'status'>('update-incomplete-finance', documentsData)
  }

  sendDocuments (documentsData: IRequestSendDocuments) {
    return this.post<StatusStatus, 'status'>('send-documents', documentsData)
  }

  completeFinanceDecline (accountData: IRequestCompleteFinanceDecline) {
    return this.post<StatusIsUpdate, 'isUpdate'>('complete-finance-decline-process', accountData)
  }

  updateAccount (accountData: IRequestUpdateAccount) {
    return this.post<StatusIsUpdate, 'isUpdate'>('update-account', accountData)
  }

  updateAndResendAccountEmails ({ subscriberId, email }: IRequestUpdateAndResendAccountEmails) {
    return this.post<StatusStatus, 'status'>('send-account-creation-emails',
      {
        subscriberId,
        email
      }, {
        transformRequest: (params: Object) => Qs.stringify(params, { arrayFormat: 'brackets' })
      })
  }

  cancelIncompleteAccount (cancelData: IRequestCancelIncomplete) {
    return this.post<StatusStatus, 'status'>('cancel-incomplete-finance', cancelData)
  }

  /**
   * store the auth token response no need to keep recalling it.
   */
  private cachedAuthTokenResponse?: {
    inFlight: boolean
    expireAt: number
    response: Promise<AuthTokenResponse>
  }
  getAuthToken (): Promise<AuthTokenResponse> {
    if (this.cachedAuthTokenResponse && !this.cachedAuthTokenResponse.inFlight) {
      // check if expired and clear out
      if (this.cachedAuthTokenResponse.expireAt < Date.now()) {
        this.cachedAuthTokenResponse = undefined
      }
    }
    if (!this.cachedAuthTokenResponse) {
      this.cachedAuthTokenResponse = {
        inFlight: true,
        expireAt: Date.now(),
        response: this.post<AuthTokenResponse, 'status'>('generate-auth-token').catch(e => {
          // unset entire cached response
          this.cachedAuthTokenResponse = undefined
          throw e
        }).then(response => {
          // replace response
          this.cachedAuthTokenResponse = {
            inFlight: false,
            expireAt: this.cachedAuthTokenResponse!.expireAt + (response.expiresIn * 1000) - 5000,
            response: Promise.resolve(response)
          }
          return response
        })
      }
    }
    // return the promise
    return this.cachedAuthTokenResponse.response
  }

  loadExistingAccount (dataForExistingAccountApi: IRequestLoadExistingAccount): Promise<IResponseExistingAccountInfo> {
    const method = dataForExistingAccountApi.searchAllSubscribers ? 'load-account' : 'load-account-to-convert'
    return this.post<IResponseExistingAccountInfo, 'status'>(method, {
      subscriberId: dataForExistingAccountApi.subscriberId
    }).then(response => {
      if (!response.status) {
        throw new Error(response.message)
      }
      return response
    })
  }

  logout () {
    this.stopWatchDog()
    if (this._logoutUrl) {
      return this.axios.get(this._logoutUrl).then(() => {})
    }
    return Promise.resolve()
  }

  async post<T extends IResultStatus<K>, K extends string & keyof T> (method: string, body?: IRequest, config?: Omit<AxiosRequestConfig, 'method' | 'url' | 'baseUrl' | 'data'>): Promise<T> {
    try {
      this.stopWatchDog()
      // XHR has no support for redirects, but unlike fetch api does send cookies
      const localRequestDate = new Date()
      const response = await this.axios.request({
        method: 'post',
        url: method,
        baseURL: `${this._basePath}/`,
        params: {
          pc: this.priceCode
        },
        data: body && {
          reghitId: this._reghitId,
          ...body
        },
        ...config
      })
      const finalUrl = new URL(method, typeof window !== 'undefined' ? new URL(`${this._basePath}/`, window.location.href) : this._basePath)
      if (response.request && response.request.responseURL && response.request.responseURL.indexOf(finalUrl.toString()) === -1) {
        throw new Error('No longer logged in')
      }
      if (response.headers && response.headers.date) {
        this.timeAdjustment.next(moment(response.headers.date).diff(localRequestDate, 'seconds') * 1000)
      }
      this.startWatchDog()
      return response.data
    } catch (e) {
      this.startWatchDog()
      throw e
    }
  }
}
